.App {
  text-align: center;
  position: relative;
}

.bookquote {
  font-style: italic;
  color: #222;
}

.textSection {
  padding-top: 30px;
  padding-bottom: 30px;
}

div[id]::before {
  content: "";
  display: block;
  height: 70px;
  margin: -70px 0 0;
}

/* box-shadow: inset 0 0 10px #000000; */
